import React from 'react';
import { Link } from 'gatsby';
import { FaChevronLeft } from 'react-icons/all';
import Author from './Author';
import Content from './Content';
import * as styles from './Post.module.scss';

const Post = ({ post }) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;

  return (
    <div className={styles.post}>
      <Link className={styles.post__homeButton} to="/" ><FaChevronLeft />Zpět</Link>

      <div>
        <Content body={html} title={title} date={date} />
      </div>

      {/*<div className={styles['post__footer']}>*/}
      {/*  /!* {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />} *!/*/}
      {/*  <Author />*/}
      {/*</div>*/}

      {/* <div className={styles['post__comments']}> */}
      {/*   <Comments postSlug={slug} postTitle={post.frontmatter.title} /> */}
      {/* </div> */}
    </div>
  );
};

export default Post;
