import React from 'react';
import * as styles from './Content.module.scss';

const Content = ({
                     body,
                     title,
                     date
                 }) => (
    <div className={styles.content}>
        <h1 className={styles.content__title}>{title}</h1>
        <div className={styles.content__published}>
            Publikováno {new Date(date).toLocaleDateString('cs-CZ', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        })}
        </div>
        <div className={styles.content__body} dangerouslySetInnerHTML={{__html: body}} />
    </div>
);

export default Content;
